import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DatadogService } from "@core/services/logging/datadog.service";
import { InstanaService } from "@core/services/logging/instana.service";
import { LogState } from "@core/services/logging/logging.enum";
import { LoggingService } from "@core/services/logging/logging.interface";
import { environment } from "@environment/environment";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';


@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    constructor(
        private loggingService: LoggingService,
        private instanaService: InstanaService,
        private dataDogService: DatadogService

        // private cryptoService: CryptoService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes(environment.apiUrl)) {
            this.loggingService.createLog('request', req.url, LogState.INIT);
            return next.handle(req).pipe(
                map(response => {
                    if (response.type === HttpEventType.Response) {
                        const formatedResponse = JSON.parse(response['body']);

                        let error = null;

                        if (formatedResponse['status']['code'] !== 201) {
                            error = formatedResponse['status']['code'];
                        }

                        this.loggingService.createLog('request', req.url, LogState.FINISH, error);
                    }

                    return response;
                })
            );
        }

        return next.handle(req);
    }
}
