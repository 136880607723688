import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-modal-loader',
    templateUrl: './modal-loader.component.html',
    styleUrls: ['./modal-loader.component.css'],
})
export class ModalLoaderComponent implements OnInit {

    @Input()
    public showModal = false;

    constructor() {
    }

    ngOnInit() {
    }

    show() {
        this.showModal = true;
    }

    hidden() {
        this.showModal = false;
    }
}
