import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  @Input()
    public hidden = true;
  @Input()
    public data = null;
  @Input()
    public dataCredit = null;
  @Output()
    confirmData = new EventEmitter<any>();

  constructor(
    private translate: TranslateService,
  ) {
    this.translate.setDefaultLang('es_CL');
  }

  ngOnInit() {
  }

  toggleModal() {
    this.hidden = !this.hidden;
    this.confirmData.emit(false);
  }

  confirmModal() {
    this.hidden = !this.hidden;
    this.confirmData.emit(true);
  }

}
