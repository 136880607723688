import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {CoreModule} from '@core/core.module';
import {TranslateModule} from '@ngx-translate/core';
import {HttpServiceModule} from 'cob-infrastructure';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from '@shared/shared.module';
import { Analytics } from 'fif-common-analytics';
import 'fif-common-analytics/dinamicLoader';
import {environment} from '@environment/environment';
import { registerLocaleData } from '@angular/common';
import localeCl from '@angular/common/locales/es-CL';
import { InstanaErrorHandler } from '@core/handlers/instana-error-handler';

const ProvidersReady = Analytics.TaggingAdapter.dynamicallyLoadProvider(environment.name, 'BFCL_WEB');

registerLocaleData(localeCl);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        CoreModule.forRoot(),
        TranslateModule.forRoot(),
        AppRoutingModule,
        SharedModule,
        RouterModule,
        BrowserAnimationsModule,
        HttpServiceModule.forRoot()
    ],
    providers: [
        Analytics.TaggingAdapter,
        { provide: LOCALE_ID, useValue: 'es-CL'},
        { provide: ErrorHandler, useClass: InstanaErrorHandler },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
