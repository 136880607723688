import {Api} from './api/api.qa';
import {App} from './app/app.local';
import {Common} from './common/common.local';

export const environment: any = {
    name: 'qa',
    gaEnv: 'qa',
    gaCommerce: 'BFCL_WEB',
    analitycsUA: 'UA-20384930-16',
    headers: {
        "accept-version": "v1",
        "x-flow-country": "CL",
        "x-flow-commerce": "BF",
        "x-flow-channel": "MOBILE",
        "x-flow-business": "BF_CL",
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    useAuthenticate: true,
    production: false,
    api: Api.apis,
    common: Common.commons,
    blobStorageUrl: 'https://static-qa.fif.tech/omni-upgrade-migration-config/bfcl/config-pago-creditos-expresspay-bfcl.json',
    publicSite: 'https://www-qa.bancofalabella.cl',
    apiUrl: `${process.env.API_URL || window['env']['apiUrl'] || 'https://pagorapidocreditos-qa.bancofalabella.cl/expresspay/web/bfcl/payments/api/v1/credit'}`,
    baseUrl: 'https://pagorapidocreditos-qa.bancofalabella.cl',
    app: App.confs,
    cipher: {
        useInRequest: true,
        useInResponse: true,
        storageKey: 'f8220093c8a07faa9d6eec88827cf0c9',
        publicKey:
            '-----BEGIN PUBLIC KEY-----\n' +
            'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr/uJqitbcJG/9jLH7lDu\n' +
            'X/jg5Q2VxDh4iwBtQvPigZiZRuChuowU9XmW+XS6J8N1lXcpe9vhErBFKX/9ELAa\n' +
            'r3PnIYVnjtVqLxro2f0eqocdWw1AN2lx01SqxiqmdHEZCsj9mEQ6sYNcNdeH6G/9\n' +
            'GNk/3Y7c0H90Gi/+vWYN1sVr+LJR5CU/emdO1W2FaQ8T2+GWhyNVOkDZEx4dTsDE\n' +
            'cX0PmGUwxPv+2gZb99fNxhwKV4Vq5Mt9ACAl3vrhUH33kZPuw3miJHKXvlw+9kuU\n' +
            'uOFJFOga/D9FMfCrYcCqlBuzf2T6w0Q19Ik+uyo3zLn3YFN1ufBfeAV51Z0kKBiT\n' +
            'zwIDAQAB\n' +
            '-----END PUBLIC KEY-----',
    },
    CAPTCHA_ENABLE: true,
    CAPTCHA_KEY: `${process.env.SITE_KEY || window['env']['siteKey'] || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}`, // 6LcL9OMUAAAAANGNe0p-LqDovPfxKMlLeiCSVjra
    instana: {
        key: 'hCZxmZ78RvOszwCGsbpvYA',
        url: 'https://eum.instana.io/eum.min.js',
    },
    EXPRESS_PAY_CREDITS: {
        URL: 'https://pagorapidocreditos-qa.bancofalabella.cl'
    },
    datadog: {
        clientToken: 'pub6d88532bb0d44a4d191028a0266baf4c',
        applicationId: 'eaf1fda6-c344-4345-ab0b-204f4ab4b85c',
        env: 'qa',
        version: `${process.env.NP_RELEASE_SEMVER || window['env']['releaseSemver'] || '1.0.0'}`
    }
};
