import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./features/banco-pe/personal-data/personal-data.module').then(m => m.PersonalDataModule),
        pathMatch: 'full'
    },
    {
        path: 'penalized',
        loadChildren: () => import('./features/banco-pe/penalized-validator/penalized-validator.module').then(m => m.PenalizedValidatorModule),
        pathMatch: 'full'
    },
    {
        path: 'consumer-credit',
        loadChildren: () => import('./features/banco-pe/consumer-credit/consumer-credit.module').then(m => m.ConsumerCreditModule)
    },
    {
        path: 'detail-credit',
        loadChildren: () => import('./features/banco-pe/detail-credit/detail-credit.module').then(m => m.DetailCreditModule)
    },
    {
        path: 'receipt-payment',
        loadChildren: () => import('./features/banco-pe/receipt-payment/receipt-payment.module').then(m => m.ReceiptPaymentModule)
    },
    {
        path: 'webpay',
        loadChildren: () => import('./features/banco-pe/webpay/webpay.module').then(m => m.WebpayModule)
    },
    {
        path: 'not-found',
        loadChildren: () => import('./features/banco-pe/not-found/not-found.module').then(m => m.NotFoundModule)
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
