import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Analytics } from 'fif-common-analytics';

@Component({
  selector: 'app-modal-action-button',
  templateUrl: './modal-action-button.component.html',
  styleUrls: ['./modal-action-button.component.scss']
})
export class ModalActionButtonComponent implements OnInit {

    @Input()
    public content = {
        title: '',
        text: '',
        actionButtonText: '',
        cancelButtonText: ''
    };

    @Input() primaryAction: () => void;
    @Input() secondaryAction: () => void;

    @Output()
    public togglePressed: EventEmitter<any> | any = new EventEmitter<any>();

    hidden = true;

    constructor(private taggingAdapter: Analytics.TaggingAdapter) {
    }

    ngOnInit() {
    }

    toggle() {
        const body = document.getElementsByTagName('body')[0];
        this.hidden = !this.hidden;
        if (this.hidden) {
            body.classList.remove('modal-open');
            this.togglePressed.emit('close-modal');
            document.getElementById('modal-info').scrollTop = 0;
        } else {
            body.classList.add('modal-open');
            this.togglePressed.emit('open-modal');
        }
    }
}
