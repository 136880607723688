export class TimeTracker {
   private processes: any = {};

   public startTrack(tag: string): void {
     this.processes[tag] = this.getCurrentTimestamp();
   }

   public endTrack(tag: string): number {
     const timeStart = this.processes[tag];
     const timeEnd = this.getCurrentTimestamp();
     const duration = timeEnd - timeStart;

     delete this.processes[tag];

     return duration;
   }

   public getCurrentTimestamp(): number {
     return Date.now();
   }
 }
