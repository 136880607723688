import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {StorageHandlerModule} from 'cob-infrastructure';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {appVersion} from 'src/app/app.version';
import {HeaderComponent} from './components/header/header.component';
import {LoginAvatarComponent} from './components/login-avatar/login-avatar.component';
import {ModalInfoComponent} from './components/modal-info/modal-info.component';
import {ModalLoaderComponent} from './components/modal-loader/modal-loader.component';
import {ProgressCircleComponent} from './components/progress-circle/progress-circle.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { ModalActionButtonComponent } from './components/modal-action-button/modal-action-button.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/detail-credit/', '.json?v=' + appVersion);
  }

@NgModule({
    imports: [
        CommonModule,
        StorageHandlerModule,
        TranslateModule.forChild({
            loader: {
              provide: TranslateLoader,
              useFactory: createTranslateLoader,
              deps: [HttpClient],
            },
            isolate: true,
          })],
    declarations: [
        HeaderComponent,
        ModalLoaderComponent,
        LoginAvatarComponent,
        ModalInfoComponent,
        ProgressCircleComponent,
        ModalConfirmComponent,
        ModalActionButtonComponent
    ],
    exports: [
        HeaderComponent,
        ModalLoaderComponent,
        LoginAvatarComponent,
        ModalInfoComponent,
        ProgressCircleComponent,
        ModalConfirmComponent,
        ModalActionButtonComponent
    ],
    providers: [],
})
export class SharedModule {
}
