import {Component, OnInit} from '@angular/core';
import { InstanaService } from '@core/services/logging/instana.service';
import { DatadogService } from '@core/services/logging/datadog.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    constructor(
        private instanaService: InstanaService,
        private datadogService: DatadogService
    ) {

    }

    ngOnInit(): void {
        this.loadDatadog();
        this.loadInstana();
        // if (environment.production) {
            // (<any> window).instanaReporting();
        // }
    }

    loadInstana = () => {
        this.instanaService.loadInstanaScript();
    }

    loadDatadog = () => {
        this.datadogService.loadScript();
    }


}
