import { Injectable } from "@angular/core";

//import { environment } from "@environment/environment";
import * as crypto from "crypto-js";

import * as jsencrypt from "jsencrypt";


const encryptor = new jsencrypt.JSEncrypt({
  default_key_size: '2048'
});

@Injectable()
export default class CryptoService {

    publicKey = '-----BEGIN PUBLIC KEY-----\n' +
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAybLfde+K7FauGHxmKKac\n' +
    'Odm79y8uFh8rGDb61CXU2oxdODlXF2KWZyCS/tfa5ZFcuCDrkTwq4RsU7OFHG7dp\n' +
    'NrlhQunEOZ7CK3FPV5AxSo/WtwttTyqrcGtYFfGPFRYf9C/hvEUawZOCAsYWvfOX\n' +
    'RF0cmLhd6+/RWqFnv9PVCnwiQ6Sf6rvUYzNTzMPW4lTyLBcehBS+ef4f8QCiItwd\n' +
    'oi+GsKDq1FCpcetC5wB67ZnUaAGMOxtkdCIo011G1TmiCwtRlQ2Wem3S3VZgnYU/\n' +
    'HLoVI8CTAOCR5eIcPb2YFy16xT6GQ+URZBvTIxPir5L1zcxcEmwGSONdb+mLHs4h\n' +
    'VQIDAQAB\n' +
    '-----END PUBLIC KEY-----';

  constructor() {
    encryptor.setPublicKey(this.publicKey);
  }

  public aesEncrypt(plainText: string, key: string): string {
    return crypto.AES.encrypt(plainText, key).toString();
  }

  public aesDecrypt(cipherAEStext: string, key: string): string {
    const bytes = crypto.AES.decrypt(cipherAEStext, key);
    return bytes.toString(crypto.enc.Utf8);
  }

  public sha256(textToDigest: string): string {
    return crypto.SHA256(textToDigest).toString();
  }

  public generateRandomKey(keyLength: number): string {
    return crypto.lib.WordArray.random(keyLength / 8).toString();
  }

  public encryptAllValuesFromObject(body: Object, key: string) {
    const bodyEnc = JSON.parse(JSON.stringify(body));
    const parametersNames = Object.keys(body);
    parametersNames.forEach((parameter) => {
      bodyEnc[parameter] =
        this.aesEncrypt(typeof (body[parameter]) !== "string" ? JSON.stringify(body[parameter]) : body[parameter], key);
    });
    return bodyEnc;
  }

  public decryptAllValuesFromObject(bodyIn: Object, sessionKey: string) {
    const bodyOut = JSON.parse(JSON.stringify(bodyIn));
    const atributes = Object.keys(bodyIn);
    atributes.forEach((atribute) => {
      const field = bodyIn[atribute];
      bodyOut[atribute] = this.aesDecrypt(field, sessionKey);
      if (bodyOut[atribute][0] === "{" || bodyOut[atribute][0] === "[") {
        bodyOut[atribute] = JSON.parse(bodyOut[atribute]);
      }
    });
    return bodyOut;
  }

  public getSignature(body: object, key: string) {
    const stringBody = JSON.stringify(body);
    const bodySha256 = this.sha256(stringBody);
    return this.aesEncrypt(bodySha256, key);
  }

  public encryptWithPublicKey(key: string) {
    return encryptor.encrypt(key);
  }

  public decryptWithPrivateKey(key: string) {
    return encryptor.decrypt(key);
  }

  public createSignature(textToSign: string, key: string) {
    const digest = this.sha256(textToSign).toString();
    return this.aesEncrypt(digest, key).toString();
  }

  verifySignature(payload: string, key: string, signatureIn: string) {
    const decipher = this.aesDecrypt(signatureIn, key).toString();
    const digest = this.sha256(payload).toString();
    return decipher === digest;
  }
}
