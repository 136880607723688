import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-modal-info',
    templateUrl: './modal-info.component.html',
    styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent implements OnInit {

    @Input()
    public content = '';

    @Output()
    public togglePressed: EventEmitter<any> | any = new EventEmitter<any>();

    hidden = true;

    constructor() {
    }

    ngOnInit() {
    }

    toggle() {
        const body = document.getElementsByTagName('body')[0];
        this.hidden = !this.hidden;
        if (this.hidden) {
            body.classList.remove('modal-open');
            this.togglePressed.emit('close-modal');
            document.getElementById('modal-info').scrollTop = 0;
        } else {
            body.classList.add('modal-open');
            this.togglePressed.emit('open-modal');
        }
    }
}
