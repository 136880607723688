import {Api} from './api/api.local';
import {App} from './app/app.local';
import {Common} from './common/common.local';

export const environment: any = {
    name: 'test',
    gaEnv: 'test',
    gaCommerce: 'BFCL_WEB',
    analitycsUA: 'UA-20384930-16',
    headers: {
        "accept-version": "v1",
        "x-flow-country": "CL",
        "x-flow-commerce": "BF",
        "x-flow-channel": "MOBILE",
        "x-flow-business": "BF_CL",
        "Content-Type": "application/json",
        "Accept": "application/json",
        "x-forwarded-for": "127.0.0.1,127.0.0.1"
    },
    useAuthenticate: true,
    production: false,
    api: Api.apis,
    common: Common.commons,
    blobStorageUrl: 'https://static-test.fif.tech/omni-upgrade-migration-config/bfcl/config-pago-creditos-expresspay-bfcl.json',
    publicSite: 'https://www-test.bancofalabella.cl',
    apiUrl: 'http://localhost:3701/web/bfcl/expresspay/api/v1/credit',
    baseUrl: 'http://localhost:4200',
    app: App.confs,
    cipher: {
        useInRequest: false,
        useInResponse: false,
        storageKey: 'f8220093c8a07faa9d6eec88827cf0c9',
        publicKey:
            '-----BEGIN PUBLIC KEY-----\n' +
            'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0vn/B66UiMc13m7Gu3+7\n' +
            'mRzkLzvzf0WMSsReSqdtZIGewcYp99KM5maW1fgIY1phxS++8AoVc34eW7T+E2xM\n' +
            't2xX2HhIC0NF+DLNvrHEyrTc+tCxt0cp8K7rvczqWWEofgrednbNnPG7jj99lraM\n' +
            'XVCpPuchRY9NRFzitKegKI3wNR1DE6C7k3QEsl2Q3bWcavyWl/j51DsBCCppuVEC\n' +
            'A5IZxjRU3gect7jKg4omk5hGvX2Gv7FOc7BC2D0yzXitLklS6BYsRm5sPMeZPnRS\n' +
            'ymi78CgCPA68AYrShDeIdzeXQTdUajjs2j1AXoU6famj/WJb9NMwHpkG8yzjL6GU\n' +
            'AQIDAQAB\n' +
            '-----END PUBLIC KEY-----',
    },
    CAPTCHA_ENABLE: true,
    CAPTCHA_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    instana: {
        key: 'hCZxmZ78RvOszwCGsbpvYA',
        url: 'https://eum.instana.io/eum.min.js',
    },
    EXPRESS_PAY: {
        URL: 'http://localhost:4200'
    },
    datadog: {
        clientToken: 'pub6d88532bb0d44a4d191028a0266baf4c',
        applicationId: 'eaf1fda6-c344-4345-ab0b-204f4ab4b85c',
        env: 'local',
        version: '1.0.0'
    }
};
