// tslint:disable: no-console
// tslint:disable: only-arrow-functions
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { TimeTracker } from './time-tracker';
import { LogState } from './logging.enum';
import { LoggingService } from './logging.interface';

const PAGORAPIDO_ANALYTICS_EVENT_CATEGORY = 'op :: pago rapido credito';
declare global {
  interface Window {
    DD_RUM: any;
  }
}

export interface LIEvent {
  category?: string;      // ej: 'ui :: pantalla validacion': Usar el mismo de Google Analytics
  action: string;         // ej: 'clic boton volver'        : Usar el mismo de Google Analytics
}

export interface LIParameters {
  page: string;       // ej: 'pr-transferencia-entre-cuentas-paso1': Usar el mismo de Google Analytics
  error?: any;     //
}

@Injectable({
  providedIn: 'root'
})

export class DatadogService implements LoggingService{

  constructor(private tracker: TimeTracker
  ) { }

  public createLog(category: string, endpoint: string, state: LogState, error?: any) {
    this.markEvent(category, endpoint, state, error);
  }

  markEvent = (category: string, endpoint: string, state: LogState, error?: any) => {
    const formatted_endpoint = endpoint.replace(environment.apiUrl, '').replace(/\//g, '_');
    const params = {
      meta: {}
    };
    if (error) {
      state = LogState.ERROR;
      params['meta']['error'] = error;
    }
    const event = `${category}_${formatted_endpoint}`.toLowerCase();
    this.addCustomEvent(event);
    if(state == LogState.INIT){
      this.addCustomEventInit(event);
    }
    if(state == LogState.FINISH){
      this.addCustomEventFinish(event);
    }
    if(state != LogState.INIT && state != LogState.FINISH){
      this.addCustomEvent(event, {state: state})
    }

    if (!environment.production) {
      console.log('<<<<<< datadog: ', event, params);
    }
  }

  public markUser(country: string, identificationType: string, identificationNumber: string) {
    throw new Error('Method not implemented.');
  }

  loadScript = (): Promise<any> => {
    const clientToken = environment.datadog.clientToken;
    const applicationId = environment.datadog.applicationId;
    const serviceName = 'banco-cl-pagorapidocreditos-ui';
    const env = environment.datadog.env;
    const version = environment.datadog.version;
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('id', 'datadog-script');
    scriptTag.setAttribute('type', 'text/javascript');
    scriptTag.text = `
      (function(h,o,u,n,d) {
        h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
        d=o.createElement(u);d.async=1;d.src=n
        n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
        })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum-v4.js','DD_RUM')
        DD_RUM.onReady(function() {
          DD_RUM.init({
            clientToken: "${clientToken}",
            applicationId:  "${applicationId}",
            site: 'datadoghq.com',
            env: "${env}",
            service: "${serviceName}",
            version: "${version}",
            sampleRate: 100,
            sessionReplaySampleRate: 20,
            trackInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            premiumSampleRate: 0,
            defaultPrivacyLevel: 'mask-user-input'
          });
        DD_RUM.startSessionReplayRecording();
      })`;
    document.body.appendChild(scriptTag);
    return Promise.resolve();
  }

  addContextUser = (hash: string): void => {
    if (window.DD_RUM !== undefined) {
      window.DD_RUM.onReady(function() {
        window.DD_RUM.addRumGlobalContext('global', { hashID: hash });
        window.DD_RUM.setUser({
          id: hash
        });
      });
    }
  }
  addCustomEvent = (eventCustom: string, extraMetaData?: any): void => {
    this.tracker.startTrack(eventCustom);
    const metaData = {
      value: eventCustom,
      ...extraMetaData
    };
    if (!environment.production) {
      console.info('<<<<<< Datadog: ', eventCustom, metaData);
    } else if (window.DD_RUM !== undefined) {
      window.DD_RUM.onReady(function() {
        window.DD_RUM.addAction(eventCustom, metaData);
      });
    }
  }

  addCustomEventInit = (eventCustom: string, extraMetaData?: any): void => {
    this.tracker.startTrack(eventCustom);
    this.addCustomEvent(`${eventCustom} init`, extraMetaData);
  }

  addCustomEventFinish = (eventCustom: string, extraMetaData?: any): void => {
    extraMetaData.duration = this.tracker.endTrack(eventCustom);
    this.addCustomEvent(`${eventCustom} finish`, extraMetaData);
  }

  addCustomError = (error: Error, eventName: string, extraMetaData?: any): void => {
    const metaData = {
      value: eventName,
      ...extraMetaData
    };
    if (!environment.production) {
      console.info('<<<<<< Datadog: ', error, metaData);
    } else if (window.DD_RUM !== undefined) {
      window.DD_RUM.onReady(function() {
        window.DD_RUM.addError(error, metaData);
      });
    }
  }
}
