import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { ScriptLoaderService } from './script-loader.service';
import { sha256 } from 'hash.js';
import { LoggingService } from './logging.interface';
import { LogState } from './logging.enum';


declare var ineum;

@Injectable({
  providedIn: 'root'
})
export class InstanaService implements LoggingService {

  constructor(
    private scriptLoaderService: ScriptLoaderService,
  ) { }

  public createLog(category: string, endpoint: string, state: LogState, error?: any) {
    this.markEvent(category, endpoint, state, error);
  }

  loadInstanaScript = (): Promise<any> => {
    const instanaKey = environment.instana.key;
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.text = `
        (function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},
        n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");
        ineum('reportingUrl', 'https://eum-red-saas.instana.io');
        ineum('key', '${instanaKey}');
        ineum('traceId', new Date().getTime());
        ineum('wrapEventHandlers', true);
        ineum('wrapTimers', true);
      `;
    document.body.appendChild(scriptTag);

    this.scriptLoaderService.load(environment.instana.url, { defer: true, crossorigin: 'anonymous' });
    return Promise.resolve();
  }

  markUser = (country: string, identificationType: string, identificationNumber: string) => {
    ineum('user', `${country.toUpperCase()}_${identificationType.toUpperCase()}_${this.hashIdentifier(identificationNumber)}`);
  }

  markEvent = (category: string, endpoint: string, state: LogState, error?: any) => {
    const formatted_endpoint = endpoint.replace(environment.apiUrl, '').replace(/\//g, '_');

    const params = {
      meta: {}
    };

    if (error) {
      state = LogState.ERROR;
      params['meta']['error'] = error;
    }

    const event = `${category}_${formatted_endpoint}_${state}`.toLowerCase();

    ineum('reportEvent', event, params);

    if (!environment.production) {
      console.log('<<<<<< Instana: ', event, params);
    }
  }

  hashIdentifier = (identifier: string): string => this.hash(this.cleanIdentifier(identifier));

  hash = (string: string): string => {
    return sha256().update(string).digest('hex');
  }

  cleanIdentifier = (id: string): string => id.replace(/[^0-9kK]+/g, '').toUpperCase();
}
